body {
  background-color: #fafafa;
  color: #424242;
  margin: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
  width: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-x: hidden;
  width: 100%;
}
